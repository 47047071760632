<!--Global Permissions-->
<fuse-card class="flex flex-col w-full mb-4 p-8 filter-article">
  <!--header-->
  <div class="flex items-center justify-between">
    <div class="text-2xl font-semibold leading-tight">Global Permissions</div>
  </div>

  <!--loading-->
  <div *ngIf="viewState == viewStates.loading">
    <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
  </div>

  <!--content-->
  <div *ngIf="viewState == viewStates.content" class="grid grid-cols-12 gap-3 text-center mt-4">
    <div class="col-span-12 md:col-span-6">
      <div *ngIf="administrator?.id == user?.id" class="text-sm text-primary mb-2">Changing your own global permissions
        is not allowed</div>
      <ng-switchery *ngIf="administrator?.id != user?.id" name="super-admin" size="switchery-md"
        [(ngModel)]="isSuperAdmin" (ngModelChange)="toggleSuperAdmin()"></ng-switchery>
      <div class="text-lg font-bold">Super Admin</div>
      <div>Super admins have access to all resource and functionality site-wide. They may also manage Global
        Permissions.</div>
    </div>
    <div class="col-span-12 md:col-span-6">
      <div *ngIf="administrator?.id == user?.id" class="text-sm text-primary mb-2">Changing your own global permissions
        is not allowed</div>
      <ng-switchery *ngIf="administrator?.id != user?.id" name="super-admin" size="switchery-md" [(ngModel)]="isAdmin"
        (ngModelChange)="toggleAdmin()"></ng-switchery>
      <div class="text-lg font-bold">Admin</div>
      <div>Admins have access to all resources site-wide but not the ability to manage Global Permission.</div>
    </div>
    <div class="col-span-12 md:col-span-6">
      <div *ngIf="administrator?.id == user?.id" class="text-sm text-primary mb-2">Changing your own global permissions
        is not allowed</div>
      <ng-switchery *ngIf="administrator?.id != user?.id" name="super-admin" size="switchery-md" [(ngModel)]="canViewJobsAnalytics"
        (ngModelChange)="toggleViewJobsAnalytics()"></ng-switchery>
      <div class="text-lg font-bold">View Jobs Analytics</div>
      <div>Jobs analytics viewers have access to view the jobs analytics page.</div>
    </div>
  </div>
</fuse-card>

<!--Content Permissions-->
<fuse-card class="flex flex-col w-full mb-4 p-8 filter-article">
  <!--header-->
  <div class="flex items-center justify-between">
    <div class="text-2xl font-semibold leading-tight">Content Permissions</div>
  </div>

  <!--loading-->
  <div *ngIf="viewState == viewStates.loading">
    <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
  </div>

  <!-- no content -->
  <div *ngIf="viewState == viewStates.content && permissions.length === 0" class="mt-8 text-center">
    <app-generic-no-content title="No Permissions Found" subtitle="No permissions were found for this staff member."
      imageUrl="https://img.icons8.com/color/256/000000/briefcase.png" class="ml-auto mr-auto mt-5">
    </app-generic-no-content>
  </div>

  <!--content-->
  <div *ngIf="viewState == viewStates.content && permissions.length > 0">
    <fuse-card class="grid grid-cols-12 gap-3 mt-4 p-4" *ngFor="let permission of contentPermissions()">
      <div class="col-span-12 md:col-span-4">
        <mat-icon *ngIf="permission.company?.imageUrl == null && permission.agency?.imageUrl == null"
          class="w-10 h-10 mr-3 text-primary" style="vertical-align: bottom;"
          [svgIcon]="'heroicons_outline:identification'"></mat-icon>
        <img *ngIf="permission.company?.imageUrl != null || permission.agency?.imageUrl != null"
          src="{{permission.company?.imageUrl ?? permission.agency?.imageUrl}}"
          class="w-10 h-10 mr-3 rounded-full object-cover inline-block" style="vertical-align: middle;" />
        <span class="whitespace-nowrap font-bold">
          {{permission.company?.name ?? permission.agency?.name}}
        </span>
      </div>
      <div class="col-span-12 md:col-span-4 text-center" style="padding-top: 8px;">
        <span class="">{{permission.permissionType.name}}</span>
      </div>
      <div class="col-span-12 md:col-span-4 text-center">
        <button class="w-full" mat-flat-button [color]="'warn'"
          [swal]="{ title: 'Delete Permission?', confirmButtonText: 'Delete', icon: 'error', confirmButtonColor: '#EF4444', showCancelButton: true }"
          (confirm)="didClickDeleteContentPermission(permission)">
          <mat-icon [svgIcon]="'delete'"></mat-icon>
          <span class="ml-2 mr-1">Delete</span>
        </button>
      </div>
    </fuse-card>
  </div>
</fuse-card>
/* tslint:disable:max-line-length */
import { Navigation } from 'app/core/navigation/navigation.types';

export const adminNavigation: Navigation = {
    default: [
        {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'basic',
            icon: 'heroicons_outline:template',
            link: '/dashboard'
        },
        {
            id: 'jobs',
            title: 'Jobs',
            subtitle: 'All things leads/matches/jobs',
            type: 'group',
            icon: 'heroicons_outline:truck',
            children: [
                {
                    id: 'leads',
                    title: 'Leads',
                    type: 'basic',
                    icon: 'heroicons_outline:chevron-double-right',
                    link: '/leads'
                },
                {
                    id: 'jobs-list',
                    title: 'Jobs',
                    type: 'basic',
                    icon: 'heroicons_outline:newspaper',
                    link: '/jobs'
                },
                {
                    id: 'agencies',
                    title: 'Agencies',
                    type: 'basic',
                    icon: 'heroicons_outline:office-building',
                    link: '/agencies'
                },
                {
                    id: 'companies',
                    title: 'Companies',
                    type: 'basic',
                    icon: 'heroicons_outline:briefcase',
                    link: '/companies'
                },
                {
                    id: 'collectives',
                    title: 'Collectives',
                    type: 'basic',
                    icon: 'heroicons_outline:collection',
                    link: '/collectives'
                },
                {
                    id: 'proposals',
                    title: 'Proposals',
                    type: 'basic',
                    icon: 'heroicons_outline:presentation-chart-bar',
                    link: '/proposals'
                },
                {
                    id: 'documents',
                    title: 'Documents',
                    type: 'basic',
                    icon: 'heroicons_outline:document-text',
                    link: '/documents'
                },
            ]
        },
        {
            id: 'advertising',
            title: 'Advertising',
            subtitle: 'Campaign and spend information',
            type: 'group',
            icon: 'heroicons_outline:cash',
            children: [
                {
                    id: 'campaigns',
                    title: 'Campaigns',
                    type: 'basic',
                    icon: 'campaign',
                    link: '/campaigns'
                },
                // {
                //     id: 'ads',
                //     title: 'Ads',
                //     type: 'basic',
                //     icon: 'heroicons_solid:newspaper',
                //     link: '/ads'
                // }
            ]
        },
        {
            id: 'people',
            title: 'People',
            subtitle: 'Users, customers, and staff',
            type: 'group',
            icon: 'heroicons_outline:users',
            children: [
                {
                    id: 'staff',
                    title: 'Staff',
                    type: 'basic',
                    icon: 'heroicons_outline:identification',
                    link: '/staff'
                },
                {
                    id: 'candidates',
                    title: 'Candidates',
                    type: 'basic',
                    icon: 'heroicons_outline:user-circle',
                    link: '/candidates'
                },
                {
                    id: 'contacts',
                    title: 'Contacts',
                    type: 'basic',
                    icon: 'contacts',
                    link: '/contacts'
                },
            ]
        }
    ],
    compact: [
        {
            id: 'example',
            title: 'Example',
            type: 'basic',
            icon: 'heroicons_outline:chart-pie',
            link: '/example'
        }
    ],
    futuristic: [
        {
            id: 'example',
            title: 'Example',
            type: 'basic',
            icon: 'heroicons_outline:chart-pie',
            link: '/example'
        }
    ],
    horizontal: [
        {
            id: 'example',
            title: 'Example',
            type: 'basic',
            icon: 'heroicons_outline:chart-pie',
            link: '/example'
        }
    ]
}

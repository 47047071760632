import { MatchedLeadsProductDto } from "./matchedLeadsProduct.dto";

export class EditMatchedLeadsProductDto {
    targetCplInCents: number;
    leadWindowOverride: number;

    static fromProduct(product: MatchedLeadsProductDto) : EditMatchedLeadsProductDto {
        const dto = new EditMatchedLeadsProductDto();
        dto.targetCplInCents = product.targetCplInCents;
        dto.leadWindowOverride = product.leadWindowOverride;

        return dto;
    }
}
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-settings-scripts',
  templateUrl: './settings-scripts.component.html',
  // styleUrls: ['./settings-scripts.component.css']
})
export class SettingsScriptsComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title, private _router: Router) { }

  ngOnInit() {
  }

  //api
  runSendLeadsService() {
    this.http
      .get(`${environment.services_tdusa_admin}/v1/scripts/send-leads`)
      .subscribe(() => {
        // this.repsViewState = ViewState.content;
      });
  }

  runNightlyAuditService() {
    this.http
      .get(`${environment.services_tdusa_admin}/v1/scripts/nightly-audit`)
      .subscribe(() => {
        // this.repsViewState = ViewState.content;
      });
  }

  runSetSpendService() {
    this.http
      .get(`${environment.services_tdusa_admin}/v1/scripts/set-spend`)
      .subscribe(() => {
        // this.repsViewState = ViewState.content;
      });
  }

  runParseXmlFeedService() {
    this.http
      .get(`${environment.services_tdusa_admin}/v1/scripts/parse-feeds`)
      .subscribe(() => {
        // this.repsViewState = ViewState.content;
      });
  }

  runParseTenstreetJobsService() {
    this.http
      .get(`${environment.services_tdusa_admin}/v1/scripts/parse-job-boards`)
      .subscribe(() => {
        // this.repsViewState = ViewState.content;
      });
  }

  runAcquireCsvService() {
    this.http
      .get(`${environment.services_tdusa_admin}/v1/scripts/acquire-csv`)
      .subscribe(() => {
        // this.repsViewState = ViewState.content;
      });
  }
}

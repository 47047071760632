<div class="grid grid-cols-12 gap-3">
  <!--Send Leads Service-->
  <div class="col-span-12 md:col-span-6">
    <fuse-card class="flex flex-col w-full mb-4 p-8 filter-article">
      <div class="text-2xl font-semibold leading-tight">Send Leads Service</div>
      <div class="text-secondary text-md mt-1">
        <b>Schedule</b> | every 10m</div>
      <div class="mt-4 mb-4">
        This script will gather qualified and unsent leads and attempt to send them to customers via ATS and/or email.
      </div>

      <button mat-flat-button color="primary"
        [swal]="{ title: 'Would you like to run the send leads service?', confirmButtonText: 'Run Service', icon: 'info',  showCancelButton: true }"
        (confirm)="runSendLeadsService()">
        Run Send Leads Service
      </button>
    </fuse-card>
  </div>

  <!--Set Spend Service-->
  <div class="col-span-12 md:col-span-6">
    <fuse-card class="flex flex-col w-full mb-4 p-8 filter-article">
      <div class="text-2xl font-semibold leading-tight">Set Spend Service</div>
      <div class="text-secondary text-md mt-1">
        <b>Schedule</b> | every morning ~ 4AM</div>
      <div class="mt-4 mb-4">
        This script will reflect on necessary spend allocations and update ad spend accordingly.
      </div>

      <button mat-flat-button color="primary"
        [swal]="{ title: 'Would you like to run the set spend service?', confirmButtonText: 'Run Service', icon: 'info',  showCancelButton: true }"
        (confirm)="runSetSpendService()">
        Run Set Spend Service
      </button>
    </fuse-card>
  </div>

  <!--Parse Feeds-->
  <div class="col-span-12 md:col-span-6">
    <fuse-card class="flex flex-col w-full mb-4 p-8 filter-article">
      <div class="text-2xl font-semibold leading-tight">Parse XML Feeds Service</div>
      <div class="text-secondary text-md mt-1">
        <b>Schedule</b> | every morning ~ 1AM</div>
      <div class="mt-4 mb-4">
        This script will parse through all customer XML feeds for matched leads products and update their jobs
        accordingly.
      </div>

      <button mat-flat-button color="primary"
        [swal]="{ title: 'Would you like to run the parse XML feeds service?', confirmButtonText: 'Run Service', icon: 'info',  showCancelButton: true }"
        (confirm)="runParseXmlFeedService()">
        Run Parse XML Feeds Service
      </button>
    </fuse-card>
  </div>

  <!--Nighlty Audit-->
  <div class="col-span-12 md:col-span-6">
    <fuse-card class="flex flex-col w-full mb-4 p-8 filter-article">
      <div class="text-2xl font-semibold leading-tight">Nightly Audit Service</div>
      <div class="text-secondary text-md mt-1">
        <b>Schedule</b> | every morning ~ 12AM</div>
      <div class="mt-4 mb-4">
        This script will reflect on all matched leads product statuses and update their current/future periods
        accordingly.
      </div>

      <button mat-flat-button color="primary"
        [swal]="{ title: 'Would you like to run the nightly audit service?', confirmButtonText: 'Run Service', icon: 'info',  showCancelButton: true }"
        (confirm)="runNightlyAuditService()">
        Run Nightly Audit Service
      </button>
    </fuse-card>
  </div>

  <!--Parse Job Boards-->
  <div class="col-span-12 md:col-span-6">
    <fuse-card class="flex flex-col w-full mb-4 p-8 filter-article">
      <div class="text-2xl font-semibold leading-tight">Parse Job Boards Service</div>
      <div class="text-secondary text-md mt-1">
        <b>Schedule</b> | every 1h at :05</div>
      <div class="mt-4 mb-4">
        This script will parse the job postings feed from all available Job Boards to look for new purchases. This includes Tenstreet, EBE, and Acquire, as supported.
      </div>

      <button mat-flat-button color="primary"
        [swal]="{ title: 'Would you like to run the Tenstreet jobs service?', confirmButtonText: 'Run Service', icon: 'info',  showCancelButton: true }"
        (confirm)="runParseTenstreetJobsService()">
        Run Parse Job Boards
      </button>
    </fuse-card>
  </div>

  <!--Acquire CSV-->
  <div class="col-span-12 md:col-span-6">
    <fuse-card class="flex flex-col w-full mb-4 p-8 filter-article">
      <div class="text-2xl font-semibold leading-tight">Acquire CSV Service</div>
      <div class="text-secondary text-md mt-1">
        <b>Schedule</b> | nightly ~11PM</div>
      <div class="mt-4 mb-4">
        This script will reflect on those jobs sourced from Acquire ROI compliant DriverReach customers and post analytics CSVs to their given FTP server for analysis
      </div>

      <button mat-flat-button color="primary"
        [swal]="{ title: 'Would you like to run the Acquire CSV service?', confirmButtonText: 'Run Service', icon: 'info',  showCancelButton: true }"
        (confirm)="runAcquireCsvService()">
        Run Acquire CSV
      </button>
    </fuse-card>
  </div>
</div>
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { Navigation } from 'app/core/navigation/navigation.types';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { AdministratorDto, PermissionDto, SystemStatusDto } from 'app/models/dtos';
import { adminNavigation } from '../../root-navigation/admin.navigation';
import { PermissionGroupsLookup, PermissionTypesLookup } from 'app/models/lookups';
import { superAdminNavigation } from '../../root-navigation/superAdmin.navigation';
import { userNavigation } from '../../root-navigation/user.navigation';
import { SystemServiceTypes } from 'app/models/app/systemServiceTypes';
import { ViewState } from 'app/models/app';
import { PermissionsService } from 'app/services/permissions/permissions.service';

@Component({
    selector: 'classic-layout',
    templateUrl: './classic.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ClassicLayoutComponent implements OnInit, OnDestroy {
    isScreenSmall: boolean;
    navigation: Navigation;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    private user: AdministratorDto;

    //services
    services = SystemServiceTypes.values;
    viewStates = ViewState;
    fbTokenViewState = ViewState.loading;
    setSpendViewState = ViewState.loading;
    leadSendViewState = ViewState.loading;
    suspisciousAppsViewState = ViewState.loading;
    tenstreetJobsAppsViewState = ViewState.loading;

    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private http: HttpClient,
        private _navigationService: NavigationService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        public permissionsService: PermissionsService
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.navigation = userNavigation;
        this.http
            .get(`${environment.services_tdusa_admin}/v1/accounts/me`)
            .subscribe((result: AdministratorDto) => {
                this.user = result;
                this.navigation = this.permissionSensitiveNav(result.permissions);
            });

        // Subscribe to navigation data
        // this._navigationService.navigation$
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe((navigation: Navigation) => {
        //         this.navigation = navigation;
        //     });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {

                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });

        //show status check, if necessary
        this.checkStatuses();
    }

    permissionSensitiveNav(permissions: PermissionDto[]): Navigation {
        if (permissions.find(p => p.permissionType.id === PermissionTypesLookup.SUPER_ADMIN.id)) {
            return superAdminNavigation;
        }
        else if (permissions.find(p => p.permissionType.id === PermissionTypesLookup.ADMIN.id)) {
            return adminNavigation;
        }
        else {
            if (permissions.find(p =>
                p.permissionType.id === PermissionTypesLookup.VIEW_CAMPAIGNS.id
                || p.permissionType.id === PermissionTypesLookup.MANAGE_CAMPAIGNS.id
                || p.permissionType.id === PermissionTypesLookup.ADMIN_CAMPAIGNS.id
                || p.permissionType.id === PermissionTypesLookup.VIEW_CAMPAIGN.id
                || p.permissionType.id === PermissionTypesLookup.MANAGE_CAMPAIGN.id
                || p.permissionType.id === PermissionTypesLookup.ADMIN_CAMPAIGN.id
                || p.permissionType.id === PermissionTypesLookup.VIEW_JOBS_ANALYTICS.id
            ) != null) {
                return adminNavigation;
            }
            else {
                return userNavigation;
            }
        }
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    //services
    async checkStatuses() {
        await this.permissionsService.setContent(PermissionGroupsLookup.GLOBAL, '');
        if (this.permissionsService.canAdmin()) {
            this.checkFBToken();
            this.checkSetSpend();
            this.checkLeadSend();
            this.checkSuspisciousApps();
            this.checkTenstreetJobPostings();
        }
    }

    checkFBToken() {
        this.fbTokenViewState = ViewState.loading;

        this.http
            .get(`${environment.services_tdusa_admin}/v1/status/facebook`)
            .subscribe((result: SystemStatusDto) => {
                const fbService = this.services.find(s => s.id == SystemServiceTypes.FB_TOKEN.id);
                fbService.isWorkingProperly = result.isWorkingProperly;

                this.fbTokenViewState = ViewState.content;
            });
    }

    checkSetSpend() {
        this.setSpendViewState = ViewState.loading;

        this.http
            .get(`${environment.services_tdusa_admin}/v1/status/set-spend`)
            .subscribe((result: SystemStatusDto) => {
                const setSpendService = this.services.find(s => s.id == SystemServiceTypes.SET_SPEND.id);
                setSpendService.isWorkingProperly = result.isWorkingProperly;

                this.setSpendViewState = ViewState.content;
            });
    }

    checkLeadSend() {
        this.leadSendViewState = ViewState.loading;

        this.http
            .get(`${environment.services_tdusa_admin}/v1/status/lead-send`)
            .subscribe((result: SystemStatusDto) => {
                const leadSendService = this.services.find(s => s.id == SystemServiceTypes.LEAD_SEND.id);
                leadSendService.isWorkingProperly = result.isWorkingProperly;

                this.leadSendViewState = ViewState.content;
            });
    }

    checkSuspisciousApps() {
        this.suspisciousAppsViewState = ViewState.loading;

        this.http
            .get(`${environment.services_tdusa_admin}/v1/status/suspicious-apps`)
            .subscribe((result: SystemStatusDto) => {
                const suspiciousAppsService = this.services.find(s => s.id == SystemServiceTypes.SUSPISCIOUS_APPS.id);
                suspiciousAppsService.isWorkingProperly = result.isWorkingProperly;

                this.suspisciousAppsViewState = ViewState.content;
            });
    }

    checkTenstreetJobPostings() {
        this.tenstreetJobsAppsViewState = ViewState.loading;

        this.http
            .get(`${environment.services_tdusa_admin}/v1/status/job-boards`)
            .subscribe((result: SystemStatusDto) => {
                const tenstreetJobsService = this.services.find(s => s.id == SystemServiceTypes.TENSTREET_JOBS.id);
                tenstreetJobsService.isWorkingProperly = result.isWorkingProperly;

                this.tenstreetJobsAppsViewState = ViewState.content;
            });
    }

    viewStateForService(service: SystemServiceTypes): ViewState {
        switch (service.id) {
            case SystemServiceTypes.FB_TOKEN.id:
                return this.fbTokenViewState;
            case SystemServiceTypes.SET_SPEND.id:
                return this.setSpendViewState;
            case SystemServiceTypes.LEAD_SEND.id:
                return this.leadSendViewState;
            case SystemServiceTypes.SUSPISCIOUS_APPS.id:
                return this.suspisciousAppsViewState;
            case SystemServiceTypes.TENSTREET_JOBS.id:
                return this.tenstreetJobsAppsViewState;
        }
    }

    statusColorForService(service: SystemServiceTypes): string {
        const viewState = this.viewStateForService(service);

        if (viewState == this.viewStates.loading) {
            return 'text-slate-500';
        }
        else if (viewState == this.viewStates.content) {
            return service.isWorkingProperly ? `text-emerald-500` : 'text-rose-500';
        }

        return 'text-slate-500';
    }

    tooltipForService(service: SystemServiceTypes): string {
        const viewState = this.viewStateForService(service);

        if (viewState == this.viewStates.loading) {
            return `Determining status of ${service.name}...`;
        }
        else if (viewState == this.viewStates.content) {

            return service.isWorkingProperly ? service.successText : service.failureText;
        }

        return 'Status Unknown';
    }
}

export class MatchedLeadsProductAtsDto {
    ebeIntegrationUrl: string;
    tenstreetReferrer: string;
    tenstreetCompanyId: string;
    avatarFleetUrl: string;
    driverReachPublicApiKey: string;
    driverReachSecretApiKey: string;
    truckRightApiAccessKey: string;
    truckRightApiSecretAccessKey: string;
    truckRightApiPostingUrl: string;
    jobcastAuthenticationKey: string;
    jobcastPassword: string;
    ebeJobRequisitionId: string;
    jobcastReferenceId: string;
    driverReachLeadSourceOverride: string;
    rmwUrl: string;
    rmwPageUrl: string;
    rmwSource: string;
    tenstreetJobPostingReferenceId: string;
    uploadAcquireCsv: boolean;
}
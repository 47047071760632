import { BaseLookup } from "../lookups";

export class JobSliceTypes extends BaseLookup {
    propName!: string;

    constructor(id: string, name: string, propName: string) {
        super(id, name);
        this.propName = propName;
    }

    // Values
    static readonly STATE = new JobSliceTypes('4', 'State', 'states');
    static readonly DRIVER_TYPE = new JobSliceTypes('5', 'Driver Type', 'driverTypes');
    static readonly CDL_CLASSES = new JobSliceTypes('6', 'CDL Classes', 'cdlClasses');
    static readonly FREIGHT_TYPE = new JobSliceTypes('7', 'Freight Type', 'freightTypes');
    static readonly EXPERIENCE_TYPE = new JobSliceTypes('8', 'Experience Type', 'experienceTypes');
    static readonly OWN_AUTHORITY = new JobSliceTypes('9', 'Has Own Authority', 'oa');
    static readonly TEAM_DRIVER = new JobSliceTypes('910', 'Team Driver', 't');

    static readonly values = [
        JobSliceTypes.STATE,
        JobSliceTypes.DRIVER_TYPE,
        JobSliceTypes.CDL_CLASSES,
        JobSliceTypes.FREIGHT_TYPE,
        // JobSliceTypes.EXPERIENCE_TYPE,
        // JobSliceTypes.OWN_AUTHORITY,
        // JobSliceTypes.TEAM_DRIVER
    ]

    static override fromId(id: string) : JobSliceTypes {
        return this.values.find(v => v.id == id);
    }
}
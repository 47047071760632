import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewState } from 'app/models/app';
import { AdvertisementSettingsDto, EditAdvertisingSettingsDto } from 'app/models/dtos';
import { environment } from 'environments/environment';
import * as moment from 'moment';

@Component({
  selector: 'app-settings-advertising',
  templateUrl: './settings-advertising.component.html',
  // styleUrls: ['./settings-advertising.component.css']
})
export class SettingsAdvertisingComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private _router: Router) { }

  settingsMeta: AdvertisementSettingsDto;
  settings: EditAdvertisingSettingsDto = new EditAdvertisingSettingsDto();
  moment = moment;

  //view states
  viewStates = ViewState;
  viewState = ViewState.loading;
  

  ngOnInit() {
    this.getSettings();
  }

  //api
  getSettings(): void {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/settings/advertisement`)
      .subscribe((result: AdvertisementSettingsDto) => {
        this.settingsMeta = result;
        this.viewState = ViewState.content;
      });
  }

  editSettings(): void {
    this.viewState = ViewState.loading;

    //trim marketing token to help fix extra space issues we have had
    this.settings.facebookMarketingToken = this.settings.facebookMarketingToken?.trim();

    this.http
      .put(`${environment.services_tdusa_admin}/v1/settings/advertisement`, this.settings)
      .subscribe(() => {
        this.settingsMeta.timestampFacebookMarketingUpdated = new Date();
        this.viewState = ViewState.content;
      });
  }

  //validations
  settingsIsValid(): Boolean {
    return (this.settings.facebookMarketingToken ?? '').trim().length > 0
  }
}

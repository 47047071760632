<!-- content -->
<div class="w-full grid grid-cols-12 gap-3">
  <!--Stas & Filters-->
  <fuse-card class="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3 mb-4 p-4 grid grid-cols-12"
    style="height: fit-content;">
    <div class="text-2xl col-span-12"><b>Stats & Filters</b></div>

    <div class="col-span-12">
      <button mat-stroked-button color="primary" class=" w-full mb-2" (click)="showSelectStates()">
        <mat-icon class="mr-2" svgIcon="map"></mat-icon>
        Filter By States
      </button>
      <div class="mb-2" *ngIf="selectedHiringStates.length > 0">
        <span class="text-small" *ngFor="let state of selectedHiringStates">
          <span>{{state.abbreviation}}</span>
          <span> | </span>
        </span>
      </div>
    </div>

    <!-- radius -->
    <div class="hidden col-span-12">
      <div class="font-bold mb-2 text-primary">Radius (slow)</div>
      <button *ngIf="leadRadius == null" mat-stroked-button class="w-full" (click)="didClickSetRadius()">
        <mat-icon class="mr-2" svgIcon="location_searching"></mat-icon>
        Set Radius
      </button>

      <div *ngIf="leadRadius != null">
        <div>{{leadRadius.radius}} miles around...</div>
        <div *ngIf="leadRadius.name != null">{{leadRadius.name}}</div>
        <div>{{leadRadius.lngLat.lat}}, {{leadRadius.lngLat.lng}}</div>
        <div class="grid grid-cols-12 mt-2 gap-3">
          <button mat-stroked-button class="col-span-6" color="warn" (click)="didClickClearRadius()">
            <mat-icon class="mr-2" svgIcon="delete_outline"></mat-icon>
            Clear
          </button>
          <button mat-stroked-button class="col-span-6" color="primary" (click)="didClickSetRadius()">
            <mat-icon class="mr-2" svgIcon="edit"></mat-icon>
            Edit
          </button>
        </div>

      </div>
      <div>
        <small>*Tip: If your radius is contained within a single state, use the state filter above to speed up your
          query!</small>
      </div>
    </div>

    <!--apps-->
    <div class="text-primary col-span-12 mt-2 mb-2">
    </div>
    <div class="col-span-8">Total</div>
    <div class="col-span-4 text-right pb-1">{{appAnalytics3.total}}</div>
    <div class="col-span-12">
      <hr class="my-2" />
    </div>

    <!--driver types-->
    <div class="text-primary col-span-12 mt-2 mb-2"><b>Driver Types</b></div>
    <div class="col-span-12 grid grid-cols-12" *ngFor="let type of driverTypes">
      <div class="col-span-8">
        <ng-switchery onLabel="{{type.name}}" [(ngModel)]="type.checked" (ngModelChange)="getResults()"
          name="{{type.id}}-{{type.name}}" size="switchery-xs"></ng-switchery>
      </div>
      <div class="col-span-4 text-right pb-1">{{driverTypeTotal(type)}}</div>
    </div>
    <div class="col-span-12 my-2"></div>

    <!--cdl classes-->
    <div class="text-primary col-span-12 mt-2 mb-2"><b>CDL Classes</b></div>
    <div class="col-span-12 grid grid-cols-12" *ngFor="let type of cdlClasses">
      <div class="col-span-8">
        <ng-switchery onLabel="{{type.name}}" [(ngModel)]="type.checked" (ngModelChange)="getResults()"
          name="{{type.id}}-{{type.name}}" size="switchery-xs"></ng-switchery>
      </div>
      <div class="col-span-4 text-right pb-1">{{cdlClassTotal(type)}}</div>
    </div>
    <div class="col-span-12 my-2"></div>

    <!--experience types-->
    <!-- <div class="text-primary col-span-12 mt-2 mb-2"><b>Experience Types</b></div>
    <div class="col-span-12 grid grid-cols-12" *ngFor="let type of experienceTypes">
      <div class="col-span-8">
        <ng-switchery onLabel="{{type.name}}" [(ngModel)]="type.checked" (ngModelChange)="getResults()"
          name="{{type.id}}-{{type.name}}" size="switchery-xs"></ng-switchery>
      </div>
      <div class="col-span-4 text-right pb-1">{{experienceTypeTotal(type)}}</div>
    </div>
    <div class="col-span-12 my-2"></div> -->

    <!--freight types-->
    <div class="text-primary col-span-12 mt-2 mb-2"><b>Freight Types</b></div>
    <div class="col-span-12 grid grid-cols-12" *ngFor="let type of freightTypes">
      <div class="col-span-8">
        <ng-switchery onLabel="{{type.name}}" [(ngModel)]="type.checked" (ngModelChange)="getResults()"
          name="{{type.id}}-{{type.name}}" size="switchery-xs"></ng-switchery>
      </div>
      <div class="col-span-4 text-right pb-1">{{freightTypeTotal(type)}}</div>
    </div>
    <div class="col-span-12 my-2"></div>
  </fuse-card>

  <!--Job Data-->
  <div class="col-span-12 md:col-span-6 lg:col-span-8 xl:col-span-9">
    <mat-tab-group animationDuration="0ms" (selectedTabChange)="tabClick($event)">
      <!-- charts -->
      <mat-tab label="Charts">
        <fuse-card class="flex flex-col mb-4">
          <!--header-->
          <div class="flex flex-row p-4">
            <mat-icon class="mr-2" svgIcon="heroicons_solid:chart-pie"></mat-icon>
            <div class="text-lg font-bold"> Jobs Analytics</div>
            <div class="flex flex-row-reverse grow items-end">
              <div class="ml-2">
                <button class="h-6 min-h-6 px-2 rounded-full bg-hover" mat-button [matMenuTriggerFor]="conversionMenu">
                  <span class="font-medium text-sm text-secondary">{{selectedSliceType.name}}</span>
                </button>
                <mat-menu #conversionMenu="matMenu">
                  <button mat-menu-item *ngFor="let type of jobSliceTypes" (click)="selectSliceType(type)">
                    <span *ngIf="type.id == selectedSliceType.id">
                      <mat-icon>check</mat-icon>
                    </span>
                    <span>{{type.name}}</span>
                  </button>
                </mat-menu>
              </div>
            </div>
          </div>
          <mat-divider class="m-0"></mat-divider>

          <div class="flex flex-col lg:flex-row">
            <!--pie chart-->
            <div class="flex-row">
              <div *ngIf="chartPie != null" class="flex flex-col flex-auto rounded-2xl overflow-hidden p-6"
                style="min-height: 450px;">
                <div class="flex items-start justify-between">

                </div>
                <div class="flex flex-col flex-auto mt-6 h-44">
                  <apx-chart class="flex flex-auto items-center justify-center w-full h-full" [series]="chartPie.series"
                    [chart]="chartPie.chart" [labels]="chartPie.labels" [legend]="chartPie.legend"
                    [responsive]="chartPie.responsive"></apx-chart>
                </div>
              </div>
            </div>

            <!--slices-->
            <div class="flex flex-col flex-auto rounded-2xl overflow-hidden p-6" style="min-height: 450px;">
              <div class="flex flex-col flex-auto mt-6 h-44" style="max-height: 350px; overflow-y: auto;">
                <ul class="pr-4">
                  <li *ngFor="let slice of rankedSliceData(sliceChartData(selectedSliceType))">
                    <div class="flex flex-col">
                      <div class="flex items-center">
                        <div class="flex-auto leading-none">
                          <div class="text-sm font-medium text-secondary">{{slice.label}}</div>
                          <div class="mt-2 font-medium text-2xl">{{slice.value | number:'1.0'}}</div>
                          <mat-progress-bar class="mt-3 rounded-full" [color]="'primary'" [mode]="'determinate'"
                            [value]="(slice.value  * 100) /sum(_slicePieData)"></mat-progress-bar>
                        </div>
                        <div class="flex items-end justify-end min-w-18 mt-auto ml-6">
                          <div class="text-lg leading-none">{{slice.value / sum(_slicePieData) | percent:'2.2'}}</div>
                        </div>
                      </div>
                      <hr class="mt-2 mb-2" />
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </fuse-card>

        <fuse-card class="flex flex-col">
          <div class="p-4 flex flex-row">
            <div class="flex flex-col">
              <div class="font-bold text-2xl">Popular Markets</div>
              <div class="">View the top locations for open positions</div>
            </div>

            <div class="flex-grow text-right">
              <mat-form-field>
                <mat-select [(value)]="selectedTopMarketsType" (valueChange)="getResults();">
                  <mat-option value="city">Cities</mat-option>
                  <mat-option value="zip">Zip Codes</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <!--loading-->
            <div *ngIf="exportViewState == viewStates.loading && permissionsService.canAdmin()" class="ml-2">Preparing
              your report. This may take a while...</div>
            <div *ngIf="exportViewState == viewStates.loading && permissionsService.canAdmin()" class="w-32">
              <mat-spinner class="m-auto" diameter="30"></mat-spinner>
            </div>
            <button *ngIf="exportViewState == viewStates.content && permissionsService.canAdmin()" class="ml-4"
              mat-flat-button [color]="'primary'" (click)="downloadJobsCsv()">
              <mat-icon [svgIcon]="'heroicons_outline:download'"></mat-icon>
              <span class="ml-2 mr-1">.csv</span>
            </button>
          </div>
          <mat-divider></mat-divider>
          <div class="flex flex-col">
            <div *ngFor="let market of topMarkets" class="p-2 px-4 flex flex-row">
              <div style="text-wrap: nowrap;">{{market.id}}</div>
              <mat-progress-bar class="rounded-full mx-2 mt-2" [color]="'primary'" [mode]="'determinate'"
                [value]="market.amount/(topMarkets[0].amount)*100"></mat-progress-bar>
              <div class="font-bold mr-2">{{market.amount}}</div>
              <div>({{market.amount/(sumAnalyticsPairs(topMarkets)) | percent:'2.2'}})</div>
            </div>
          </div>
        </fuse-card>
      </mat-tab>

      <!--map-->
      <mat-tab label="Map">
        <ng-template matTabContent>
          <div class="flex flex-col gap-3">
            <mat-progress-bar *ngIf="mapViewState == viewStates.loading" mode="indeterminate"></mat-progress-bar>
            <fuse-card>
              <mgl-map [style]="'mapbox://styles/mapbox/light-v11'" [style.width]="'100%'" [style.height]="'400px'"
              [style.borderRadius]="'16px'" [zoom]="3" [center]="[-95.7, 37]" (mapCreate)="map = $event"
              (mapLoad)="onMapLoad($event)" [attributionControl]="true" [preserveDrawingBuffer]="true">
            </mgl-map>
            </fuse-card>
  
            <!--locations list-->
            <fuse-card class="flex flex-col">
              <div class="p-4 flex flex-row">
                <div class="flex flex-col">
                  <div class="font-bold text-2xl">Map Locations {{_mapFilteredLocations.length}}</div>
                  <div class="text-sm">View jobs within the map frame above. For performance reasons, only the first 50 results are shown</div>
                </div>
              </div>
              <mat-divider></mat-divider>
              <div class="flex flex-col">
                <div *ngFor="let location of _mapFilteredLocations; trackBy: trackByFn;  let i=index" class="p-2 px-4 flex flex-row">
                  <div *ngIf="i < 50" class="flex flex-col text-sm">
                    <div class="font-bold">{{location.name}}</div>
                    <div>({{location.description}} | {{location.radius ?? 50}} mi.)</div>
                  </div>
                </div>
              </div>
            </fuse-card>
          </div>
          
        </ng-template>
      </mat-tab>
    </mat-tab-group>

  </div>
</div>
import { BaseLookup } from "./base.lookup";

export class ServicesLookup extends BaseLookup {
  
    // Values
    static readonly XML_PARSER = new ServicesLookup('1', 'XML Parser Service');
    static readonly SET_SPEND = new ServicesLookup('2', 'Set Spend Service');
    static readonly SEND_LEADS = new ServicesLookup('3', 'Send Leads Service');
    static readonly NIGHTLY_AUDIT = new ServicesLookup('4', 'Nightly Audit Service');
    static readonly JOB_BOARDS = new ServicesLookup('5', 'Job Boards Service');
    static readonly ACQUIRE_CSV = new ServicesLookup('6', 'Acquire CSV Service');

    static readonly values = [
        ServicesLookup.XML_PARSER,
        ServicesLookup.SET_SPEND,
        ServicesLookup.SEND_LEADS,
        ServicesLookup.NIGHTLY_AUDIT,
        ServicesLookup.JOB_BOARDS,
        ServicesLookup.ACQUIRE_CSV,
    ]
  }
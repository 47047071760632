import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ViewState } from 'app/models/app';
import { AddPermissionDto, AdministratorDto, CompanyDto, PermissionDto } from 'app/models/dtos';
import { PermissionTypesLookup } from 'app/models/lookups';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-staff-member-permissions',
  templateUrl: './staff-member-permissions.component.html',
  // styleUrls: ['./staff-member-permissions.component.css']
})
export class StaffMemberPermissionsComponent implements OnInit {
  constructor(private http: HttpClient) { }

  //inputs
  @Input() administrator: AdministratorDto;

  //vars
  permissions: PermissionDto[] = [];
  isSuperAdmin: boolean = false;
  isAdmin: boolean = false;
  canViewJobsAnalytics: boolean = false;
  user: AdministratorDto;


  //view states
  viewStates = ViewState;
  viewState = ViewState.loading;

  ngOnInit() {
    this.tryGetUser();
    this.getPermissions();
  }

  //api
  tryGetUser() {
    this.http
      .get(`${environment.services_tdusa_admin}/v1/accounts/me`)
      .subscribe((result: AdministratorDto) => {
        this.user = result;
      });
  }

  getPermissions(): void {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/administrators/${this.administrator.id}/permissions`)
      .subscribe((result: PermissionDto[]) => {
        this.permissions = result;
        this.applyGlobalPermissions();
        this.viewState = ViewState.content;
      });
  }

  addPermission(permissionTypeId: string): void {
    this.viewState = ViewState.loading;

    this.http
      .post(`${environment.services_tdusa_admin}/v1/administrators/${this.administrator.id}/permissions`, AddPermissionDto.fromIds(permissionTypeId, this.administrator.id))
      .subscribe((result: PermissionDto) => {
        this.permissions.push(result);
        this.applyGlobalPermissions();
        this.viewState = ViewState.content;
      });
  }

  deletePermission(permissionId: string): void {
    this.viewState = ViewState.loading;

    this.http
      .delete(`${environment.services_tdusa_admin}/v1/administrators/${this.administrator.id}/permissions/${permissionId}`)
      .subscribe((result: PermissionDto[]) => {
        this.permissions = this.permissions.filter(p => p.id !== permissionId);
        this.viewState = ViewState.content;
      });
  }

  //permission management
  applyGlobalPermissions() {
    this.isSuperAdmin = this.permissions.find(p => p.permissionType.id === PermissionTypesLookup.SUPER_ADMIN.id) != null;
    this.isAdmin = this.permissions.find(p => p.permissionType.id === PermissionTypesLookup.ADMIN.id) != null;
    this.canViewJobsAnalytics = this.permissions.find(p => p.permissionType.id === PermissionTypesLookup.VIEW_JOBS_ANALYTICS.id) != null;
  }

  toggleSuperAdmin() {
    if (this.isSuperAdmin) {
      this.addPermission(PermissionTypesLookup.SUPER_ADMIN.id);
    }
    else {
      this.deletePermission(this.permissions.find(p => p.permissionType.id === PermissionTypesLookup.SUPER_ADMIN.id)?.id)
    }
  }

  toggleAdmin() {
    if (this.isAdmin) {
      this.addPermission(PermissionTypesLookup.ADMIN.id);
    }
    else {
      this.deletePermission(this.permissions.find(p => p.permissionType.id === PermissionTypesLookup.ADMIN.id)?.id)
    }
  }

  toggleViewJobsAnalytics() {
    if (this.canViewJobsAnalytics) {
      this.addPermission(PermissionTypesLookup.VIEW_JOBS_ANALYTICS.id);
    }
    else {
      this.deletePermission(this.permissions.find(p => p.permissionType.id === PermissionTypesLookup.VIEW_JOBS_ANALYTICS.id)?.id)
    }
  }

  //content permissions
  contentPermissions(): PermissionDto[] {
    return this.permissions.filter(p => p.permissionType.id != PermissionTypesLookup.SUPER_ADMIN.id && p.permissionType.id != PermissionTypesLookup.SUPER_ADMIN.id);
  }

  didClickDeleteContentPermission(permission: PermissionDto) {
    this.deletePermission(permission.id);
  }
}

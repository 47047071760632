<!--loading-->
<div *ngIf="viewState == viewStates.loading">
  <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
</div>

<div *ngIf="viewState == viewStates.content">
  <!--Tenstreet-->
  <fuse-card class="flex flex-col w-full mb-4 p-8 filter-article">
    <!--header-->
    <div class="flex items-center justify-between">
      <div class="text-2xl font-semibold leading-tight">Tenstreet Integration</div>
      <div class="-mr-3">
        <button mat-flat-button [color]="'primary'" (click)="editAts()">
          <mat-icon [svgIcon]="'heroicons_outline:save'"></mat-icon>
          <span class="ml-2 mr-1">Save</span>
        </button>
      </div>
    </div>
    <div class="mt-4 mb-4 text-sm text-center">
      Integrating Tenstreet means that applications
      will be submitted directly to Tenstreet.

      If you're unsure of your Tenstreet Company ID, please email
      integrations@tenstreet.com for more information.
      You can also email us at apps@truckdriversus.com.
    </div>
    <mat-form-field class="w-full mt-2">
      <mat-label *ngIf="(ats.tenstreetCompanyId ?? '') != ''"><small>Tenstreet Company ID</small></mat-label>
      <input matInput [(ngModel)]="ats.tenstreetCompanyId" [autocomplete]="'off'" placeholder="Tenstreet Company ID">
      <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_solid:chevron-double-right'"></mat-icon>
    </mat-form-field>

    <mat-form-field class="w-full mt-4">
      <mat-label *ngIf="(ats.tenstreetReferrer ?? '') != ''"><small>Tenstreet Referrer</small></mat-label>
      <input matInput [(ngModel)]="ats.tenstreetReferrer" [autocomplete]="'off'" placeholder="Tenstreet Referrer">
      <mat-icon class="icon-size-5" matSuffix [svgIcon]="'link'"></mat-icon>
    </mat-form-field>

    <mat-form-field class="w-full mt-4">
      <mat-label *ngIf="(ats.tenstreetJobPostingReferenceId ?? '') != ''"><small>Tenstreet Job Posting Reference ID</small></mat-label>
      <input matInput [(ngModel)]="ats.tenstreetJobPostingReferenceId" [autocomplete]="'off'" placeholder="Tenstreet Job Posting Reference ID">
      <mat-icon class="icon-size-5" matSuffix [svgIcon]="'link'"></mat-icon>
    </mat-form-field>
    <div class="text-sm">
      *Note: This field should be populated for products generated from the Tenstreet Jobs Marketplace.
    </div>
  </fuse-card>

  <!--EBE-->
  <fuse-card class="flex flex-col w-full mb-4 p-8 filter-article">
    <!--header-->
    <div class="flex items-center justify-between">
      <div class="text-2xl font-semibold leading-tight">EBE Integration</div>
      <div class="-mr-3">
        <button mat-flat-button [color]="'primary'" (click)="editAts()">
          <mat-icon [svgIcon]="'heroicons_outline:save'"></mat-icon>
          <span class="ml-2 mr-1">Save</span>
        </button>
      </div>
    </div>
    <div class="mt-4 mb-4 text-sm text-center">
      EBE is a recruiting platform for the transportation and logistics
      industry. Integrating EBE with TUDSA means that applications
      will be submitted directly to EBE.
    </div>
    <mat-form-field class="w-full">
      <mat-label *ngIf="(ats.ebeIntegrationUrl ?? '') != ''"><small>EBE Integration URL</small></mat-label>
      <input matInput [(ngModel)]="ats.ebeIntegrationUrl" [autocomplete]="'off'" placeholder="EBE Integration URL">
      <mat-icon class="icon-size-5" matSuffix [svgIcon]="'link'"></mat-icon>
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label *ngIf="(ats.ebeJobRequisitionId ?? '') != ''"><small>EBE Job Requisition ID</small></mat-label>
      <input matInput [(ngModel)]="ats.ebeJobRequisitionId" [autocomplete]="'off'" placeholder="EBE Job Requisition ID">
      <mat-icon class="icon-size-5" matSuffix [svgIcon]="'vpn_key'"></mat-icon>
    </mat-form-field>
  </fuse-card>

  <!--Driver Reach-->
  <fuse-card class="flex flex-col w-full mb-4 p-8 filter-article">
    <!--header-->
    <div class="flex items-center justify-between">
      <div class="text-2xl font-semibold leading-tight">DriverReach Integration</div>
      <div class="-mr-3">
        <button mat-flat-button [color]="'primary'" (click)="editAts()">
          <mat-icon [svgIcon]="'heroicons_outline:save'"></mat-icon>
          <span class="ml-2 mr-1">Save</span>
        </button>
      </div>
    </div>
    <div class="mt-4 mb-4 text-sm text-center">
      DriverReach is a recruiting managment system for the transportation and logistics industry. Integrating
      DriverReach with TUDSA means that applications will be submitted directly to DriverReach.

      If you're unsure of your DriverReach API Key, please contact them for more information.
      You can also email us at apps@truckdriversus.com.
    </div>
    <!--public api key-->
    <mat-form-field class="w-full">
      <mat-label *ngIf="(ats.driverReachPublicApiKey ?? '') != ''"><small>Public API Key</small></mat-label>
      <input matInput [(ngModel)]="ats.driverReachPublicApiKey" placeholder="Public API Key">
      <mat-icon class="icon-size-5" matSuffix [svgIcon]="'vpn_key'"></mat-icon>
    </mat-form-field>

    <!--secret api key-->
    <mat-form-field class="w-full">
      <mat-label *ngIf="(ats.driverReachSecretApiKey ?? '') != ''"><small>Secret API Key</small></mat-label>
      <input matInput [(ngModel)]="ats.driverReachSecretApiKey" placeholder="Secret API Key">
      <mat-icon class="icon-size-5" matSuffix [svgIcon]="'vpn_key'"></mat-icon>
    </mat-form-field>

    <!--lead source override-->
    <mat-form-field class="w-full">
      <mat-label *ngIf="(ats.driverReachLeadSourceOverride ?? '') != ''"><small>Lead Source Override</small></mat-label>
      <input matInput [(ngModel)]="ats.driverReachLeadSourceOverride" placeholder="e.g. Lead Source Override">
      <mat-icon class="icon-size-5" matSuffix [svgIcon]="'link'"></mat-icon>
    </mat-form-field>
    <div class="text-sm">
      *Note: If no override is provided, a default one is provided by the system.
    </div>
  </fuse-card>

  <!--TruckRight-->
  <fuse-card class="flex flex-col w-full mb-4 p-8 filter-article">
    <!--header-->
    <div class="flex items-center justify-between">
      <div class="text-2xl font-semibold leading-tight">TruckRight Integration</div>
      <div class="-mr-3">
        <button mat-flat-button [color]="'primary'" (click)="editAts()">
          <mat-icon [svgIcon]="'heroicons_outline:save'"></mat-icon>
          <span class="ml-2 mr-1">Save</span>
        </button>
      </div>
    </div>
    <div class="mt-4 mb-4 text-sm text-center">
      Integrating TruckRight with TUDSA means that applications will be submitted directly to TruckRight.

      If you're unsure of your TruckRight API Key, please contact them for more information.
      You can also email us at apps@truckdriversus.com.
    </div>
    <!--api access key-->
    <mat-form-field class="w-full">
      <mat-label *ngIf="(ats.truckRightApiAccessKey ?? '') != ''"><small>API Access Key</small></mat-label>
      <input matInput [(ngModel)]="ats.truckRightApiAccessKey" placeholder="API Access Key">
      <mat-icon class="icon-size-5" matSuffix [svgIcon]="'vpn_key'"></mat-icon>
    </mat-form-field>

    <!--api secret access key-->
    <mat-form-field class="w-full">
      <mat-label *ngIf="(ats.truckRightApiSecretAccessKey ?? '') != ''"><small>API Secret Access Key</small></mat-label>
      <input matInput [(ngModel)]="ats.truckRightApiSecretAccessKey" placeholder="API Secret Access Key">
      <mat-icon class="icon-size-5" matSuffix [svgIcon]="'vpn_key'"></mat-icon>
    </mat-form-field>

    <!--api posting url-->
    <mat-form-field class="w-full">
      <mat-label *ngIf="(ats.truckRightApiPostingUrl ?? '') != ''"><small>API Posting URL</small></mat-label>
      <input matInput [(ngModel)]="ats.truckRightApiPostingUrl" placeholder="API Posting URL">
      <mat-icon class="icon-size-5" matSuffix [svgIcon]="'link'"></mat-icon>
    </mat-form-field>
  </fuse-card>

  <!--JobCast-->
  <fuse-card class="flex flex-col w-full mb-4 p-8 filter-article">
    <!--header-->
    <div class="flex items-center justify-between">
      <div class="text-2xl font-semibold leading-tight">JobCast Integration</div>
      <div class="-mr-3">
        <button mat-flat-button [color]="'primary'" (click)="editAts()">
          <mat-icon [svgIcon]="'heroicons_outline:save'"></mat-icon>
          <span class="ml-2 mr-1">Save</span>
        </button>
      </div>
    </div>
    <div class="mt-4 mb-4 text-sm text-center">
      Integrating JobCast with TUDSA means that applications will be submitted directly to JobCast.

      If you're unsure of your Authentication Key, please contact JobCast for more information.
      You can also email us at apps@truckdriversus.com.
    </div>

    <!--authentication key-->
    <mat-form-field class="w-full">
      <mat-label *ngIf="(ats.jobcastAuthenticationKey ?? '') != ''"><small>Authentication Key</small></mat-label>
      <input matInput [(ngModel)]="ats.jobcastAuthenticationKey" placeholder="Authentication Key">
      <mat-icon class="icon-size-5" matSuffix [svgIcon]="'vpn_key'"></mat-icon>
    </mat-form-field>

    <!--password-->
    <mat-form-field class="w-full">
      <mat-label *ngIf="(ats.jobcastPassword ?? '') != ''"><small>Password</small></mat-label>
      <input matInput [(ngModel)]="ats.jobcastPassword" placeholder="Password">
      <mat-icon class="icon-size-5" matSuffix [svgIcon]="'lock'"></mat-icon>
    </mat-form-field>

    <!--reference id-->
    <mat-form-field class="w-full">
      <mat-label  *ngIf="(ats.jobcastReferenceId ?? '') != ''"><small>Reference ID</small></mat-label>
      <input matInput [(ngModel)]="ats.jobcastReferenceId" placeholder="Reference ID">
      <mat-icon class="icon-size-5" matSuffix [svgIcon]="'badge'"></mat-icon>
    </mat-form-field>
  </fuse-card>


  <!--AvatarFleet-->
  <fuse-card class="flex flex-col w-full mb-4 p-8 filter-article">
    <!--header-->
    <div class="flex items-center justify-between">
      <div class="text-2xl font-semibold leading-tight">AvatarFleet Integration</div>
      <div class="-mr-3">
        <button mat-flat-button [color]="'primary'" (click)="editAts()">
          <mat-icon [svgIcon]="'heroicons_outline:save'"></mat-icon>
          <span class="ml-2 mr-1">Save</span>
        </button>
      </div>
    </div>
    <div class="mt-4 mb-4 text-sm text-center">
      Integrating AvatarFleet with TUDSA means that applications will be submitted directly to AvatarFleet.

      If you're unsure of your AvatarFleet URL, please contact us at apps@truckdriversus.com.
    </div>
    <mat-form-field class="w-full">
      <mat-label *ngIf="(ats.avatarFleetUrl ?? '') != ''"><small>Avatar Fleet URL</small></mat-label>
      <input matInput [(ngModel)]="ats.avatarFleetUrl" [autocomplete]="'off'" placeholder="Avatar Fleet URL">
      <mat-icon class="icon-size-5" matSuffix [svgIcon]="'link'"></mat-icon>
    </mat-form-field>
  </fuse-card>

  <!--Ramsey-->
  <fuse-card class="flex flex-col w-full mb-4 p-8 filter-article">
    <!--header-->
    <div class="flex items-center justify-between">
      <div class="text-2xl font-semibold leading-tight">Ramsey Mediaworks Integration</div>
      <div class="-mr-3">
        <button mat-flat-button [color]="'primary'" (click)="editAts()">
          <mat-icon [svgIcon]="'heroicons_outline:save'"></mat-icon>
          <span class="ml-2 mr-1">Save</span>
        </button>
      </div>
    </div>
    <div class="mt-4 mb-4 text-sm text-center">
      Integrating Ramsey MediaWorks (RMW) with TUDSA means that applications will be submitted directly to RMW.

      If you're unsure of your RMW values or requirements, please contact us at apps@truckdriversus.com.
    </div>
    <mat-form-field class="w-full">
      <mat-label *ngIf="(ats.rmwUrl ?? '') != ''"><small>RMW Integration URL</small></mat-label>
      <input matInput [(ngModel)]="ats.rmwUrl" [autocomplete]="'off'" placeholder="Integration URL (e.g. https://______.com/vendor_importer/prod/index.php)">
      <mat-icon class="icon-size-5" matSuffix [svgIcon]="'link'"></mat-icon>
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label *ngIf="(ats.rmwSource ?? '') != ''"><small>RMW Source</small></mat-label>
      <input matInput [(ngModel)]="ats.rmwSource" [autocomplete]="'off'" placeholder="Source (e.g. LP_product_src...)">
      <mat-icon class="icon-size-5" matSuffix [svgIcon]="'badge'"></mat-icon>
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label *ngIf="(ats.rmwPageUrl ?? '') != ''"><small>Page URL (optional)</small></mat-label>
      <input matInput [(ngModel)]="ats.rmwPageUrl" [autocomplete]="'off'" placeholder="Referring page URL">
      <mat-icon class="icon-size-5" matSuffix [svgIcon]="'link'"></mat-icon>
    </mat-form-field>
  </fuse-card>

  <!--Acquire ROI-->
  <fuse-card class="flex flex-col w-full mb-4 p-8 filter-article">
    <!--header-->
    <div class="flex items-center justify-between">
      <div class="text-2xl font-semibold leading-tight">Acquire ROI</div>
      <div class="-mr-3">
        <button mat-flat-button [color]="'primary'" (click)="editAts()">
          <mat-icon [svgIcon]="'heroicons_outline:save'"></mat-icon>
          <span class="ml-2 mr-1">Save</span>
        </button>
      </div>
    </div>
    <div class="mt-4 mb-4 text-sm text-center">
      Integrating Acquire ROI with TUDSA means that each night the analytics for this product will be bundled into a CSV and uploaded as part of a nightly script.
    </div>
    <mat-checkbox class="p-2 ml-auto mr-auto" [color]="'primary'" [(ngModel)]="ats.uploadAcquireCsv"
          >
          Enable CSV upload for this product
        </mat-checkbox>
  </fuse-card>
</div>

<app-matched-leads-product-webhooks [company]="company" [product]="product"></app-matched-leads-product-webhooks>
import { AppAnalyticPair } from "./appAnalytics2.dto";

export class JobAnaylyticsDto {
    total: number = 0;

    states: AppAnalyticPair[] = [];
    driverTypes: AppAnalyticPair[] = [];
    cdlClasses: AppAnalyticPair[] = [];
    experienceTypes: AppAnalyticPair[] = [];
    freightTypes: AppAnalyticPair[] = [];

    military: number = 0;
    interestInOoLp: number = 0;
    interestInTeams: number = 0;
    teamDriver: number = 0;
}
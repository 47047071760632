import { AnyLayer, AnySourceData } from "mapbox-gl";
import * as turf from '@turf/turf'
import { HiringCityDto, HiringZipDto, MatchedLeadsProductHiringAreaDto } from "app/models/dtos";

export class TdusaMapElements {
    //tags
    static readonly statesBordersLayerTag = 'state-borders';
    static readonly statesFillsLayerTag = 'state-fills';
    static readonly statesFillsHoverLayerTag = 'state-fills-hover';
    static readonly statesFillsActiveLayerTag = 'state-fills-active';
    static readonly zipsLayerTag = 'zips';
    static readonly citiesLayerTag = 'cities';
    static readonly areasLayerTag = 'areas';
    static readonly jobsLocationsLayerTag = 'jobsLocations';

    static readonly statesSourceTag = 'states';
    static readonly zipsSourceTag = 'zips';
    static readonly citiesSourceTag = 'cities';
    static readonly areasSourceTag = 'areas';
    static readonly jobsLocationsSourceTag = 'jobsLocations';

    //layers
    static zipsLayer(): AnyLayer {
        return {
            id: this.zipsLayerTag,
            type: 'fill',
            source: this.zipsSourceTag,
            layout: {},
            paint: {
                'fill-opacity': 0.5,
                'fill-color': '#ff8c00',
                'fill-outline-color': '#ff8c00',
            },
        }
    }

    static reportZipsLayer(): AnyLayer {
        return {
            id: this.zipsLayerTag,
            type: 'fill',
            source: this.zipsSourceTag,
            layout: {},
            paint: {
                'fill-opacity': 1,
                'fill-color': '#ff8c00',
                'fill-outline-color': '#ff8c00',
            },
        }
    }

    static jobsZipsLayer(): AnyLayer {
        return {
            id: this.jobsLocationsLayerTag,
            type: 'circle',
            source: this.jobsLocationsSourceTag,
            layout: {},
            // paint: {
            //     "heatmap-color": [
            //         "interpolate", 
            //         ["linear"], 
            //         ["heatmap-density"], 0, "rgba(0, 0, 255, 0)", 0.1, "#ffffb2", 0.3, "#feb24c", 0.5, "#fd8d3c", 0.7, "#fc4e2a", 1, "#e31a1c"
            //     ],
            // }
            // paint: {
            //     'fill-opacity': 0.15,
            //     'fill-color': '#ff8c00',
            //     'fill-outline-color': '#ff8c00',
            // },
            filter: ['has', 'point_count'],
            paint: {
                // Use step expressions (https://docs.mapbox.com/style-spec/reference/expressions/#step)
                // with three steps to implement three types of circles:
                //   * Blue, 20px circles when point count is less than 100
                //   * Yellow, 30px circles when point count is between 100 and 750
                //   * Pink, 40px circles when point count is greater than or equal to 750
                'circle-color': [
                    'step',
                    ['get', 'point_count'],
                    '#51bbd6',
                    100,
                    '#f1f075',
                    750,
                    '#f28cb1'
                ],
                'circle-radius': [
                    'step',
                    ['get', 'point_count'],
                    20,
                    100,
                    30,
                    750,
                    40
                ]
            }
        }
    }

    static stateBordersLayer(): AnyLayer {
        return {
            id: this.statesBordersLayerTag,
            type: 'line',
            source: this.statesSourceTag,
            layout: {},
            filter: ['all', ['!=', 'postal', 'AK'], ['!=', 'postal', 'HI']],
            paint: {
                // jscs:disable
                'line-color': '#0C5899',
                'line-width': 2,
                // jscs:enable
            },
        }
    }

    static citiesLayer(): AnyLayer {
        return {
            id: this.citiesLayerTag,
            type: 'fill',
            source: this.citiesSourceTag,
            layout: {},
            paint: {
                'fill-opacity': 0.5,
                'fill-color': '#ff007b',
                'fill-outline-color': '#ff007b',
            },
        }
    }

    static areasLayer(): AnyLayer {
        return {
            id: this.areasLayerTag,
            type: 'fill',
            source: this.areasSourceTag,
            layout: {},
            paint: {
                'fill-opacity': 0.5,
                'fill-color': '#8000ff',
                'fill-outline-color': '#8000ff',
            },
        }
    }

    static stateFillsLayer(): AnyLayer {
        return {
            id: this.statesFillsLayerTag,
            type: 'fill',
            source: this.statesSourceTag,
            layout: {},
            filter: ['all', ['!=', 'postal', 'AK'], ['!=', 'postal', 'HI']],
            paint: {
                // jscs:disable
                'fill-color': '#0C5899',
                'fill-opacity': 0.3,
                // jscs:enable
            },
        }
    }

    static stateFillsActiveLayer(): AnyLayer {
        return {
            id: this.statesFillsActiveLayerTag,
            type: 'fill',
            source: this.statesSourceTag,
            layout: {},
            filter: ['all', ['!=', 'postal', 'AK'], ['!=', 'postal', 'HI']],
            paint: {
                // jscs:disable
                'fill-color': '#0C5899',
                'fill-opacity': 0.7,
                // jscs:enable
            },
            // filter: stateFilter(opts.states),
        }
    }

    static stateFillsHoverLayer(): AnyLayer {
        return {
            id: this.statesFillsHoverLayerTag,
            type: 'line',
            source: this.statesSourceTag,
            layout: {},
            paint: {
                // jscs:disable
                'line-color': '#02A161',
                'line-width': 3,
                // jscs:enable
            },
            filter: ['any'],
        }
    }

    //#region markers
    static circleMarkerForHiringZip(hiringZip: HiringZipDto, radius: number): any {
        return TdusaMapElements.circleMarker([hiringZip.lng, hiringZip.lat], radius);
    }

    static circleMarkerForHiringCity(hiringCity: HiringCityDto, radius: number): any {
        return TdusaMapElements.circleMarker([hiringCity.lng, hiringCity.lat], radius);
    }

    static circleMarkerForHiringArea(hiringArea: MatchedLeadsProductHiringAreaDto): any {
        return TdusaMapElements.circleMarker([hiringArea.lng, hiringArea.lat], hiringArea.radius);
    }

    static circleMarkerForLatLng(lat: number, lng: number, radius: number): any {
        return TdusaMapElements.circleMarker([lng, lat], radius);
    }

    static pointMarkerForLatLng(lat: number, lng: number): any {
        return TdusaMapElements.pointMarker([lng, lat]);
    }

    static circleMarker(point: number[], radius: number) {
        return turf.circle(point, radius, {
            steps: 50,
            units: 'miles'
        });
    }

    static pointMarker(point: number[]) {
        return turf.point(point);
    }

    //sources
    static statesMapSource(): AnySourceData {
        return {
            type: 'geojson',
            data: '/assets/geojson/us-states.json'
        };
    }
}
import { BaseLookup } from "./base.lookup";

export class PermissionTypesLookup extends BaseLookup {

  // GLOBAL
  static readonly SUPER_ADMIN = new PermissionTypesLookup('1', 'Super Admin');
  static readonly ADMIN = new PermissionTypesLookup('2', 'Admin');
  static readonly VIEW_JOBS_ANALYTICS = new PermissionTypesLookup('15', 'View Jobs Analytics');

  //COMPANY
  static readonly VIEW_COMPANY = new PermissionTypesLookup('3', 'View Company');
  static readonly MANAGE_COMPANY = new PermissionTypesLookup('4', 'Manage Company');
  static readonly ADMIN_COMPANY = new PermissionTypesLookup('5', 'Administer Company');

  //AGENCY
  static readonly VIEW_AGENCY = new PermissionTypesLookup('6', 'View Agency');
  static readonly MANAGE_AGENCY = new PermissionTypesLookup('7', 'Manage Agency');
  static readonly ADMIN_AGENCY = new PermissionTypesLookup('8', 'Administer Agency');

  //Campaigns
  static readonly VIEW_CAMPAIGN = new PermissionTypesLookup('9', 'View Campaign');
  static readonly MANAGE_CAMPAIGN = new PermissionTypesLookup('10', 'Manage Campaign');
  static readonly ADMIN_CAMPAIGN = new PermissionTypesLookup('11', 'Administer Campaign');
  static readonly VIEW_CAMPAIGNS = new PermissionTypesLookup('12', 'View Campaigns');
  static readonly MANAGE_CAMPAIGNS = new PermissionTypesLookup('13', 'Manage Campaigns');
  static readonly ADMIN_CAMPAIGNS = new PermissionTypesLookup('14', 'Administer Campaigns');
}
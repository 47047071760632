<div class="flex flex-col flex-auto min-w-0">

  <!-- Header -->
  <!-- <app-generic-breadcrumbs terminal="Jobs"></app-generic-breadcrumbs> -->

  <!-- Main -->
  <div class="flex-auto p-6 sm:p-10">

    <!--loading-->
    <div *ngIf="viewState == viewStates.loading" class="w-full">
      <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
    </div>

    <!-- content -->
    <app-jobs-analytics *ngIf="viewState == viewStates.content" jobRoute="v1/jobs" [permissionsService]="permissionsService"></app-jobs-analytics>
  </div>

</div>